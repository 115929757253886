import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpResponse,
  HttpRequest,
  HttpHandler,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/pages/admin/login/auth.service';
import { MessageService } from 'src/helper/message.service';
import { Router } from '@angular/router';

@Injectable()
export class MyInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private messageservice: MessageService,
    private router: Router
  ) { }
  private isRefreshing = false;

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let token = localStorage.getItem('token');

    if (token) {
      this.authService.ValidateJWT();
      req = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token}`),
      });
    }

    return next.handle(req).pipe(
      catchError((err) => {
        if ([401].indexOf(err.status) !== -1) {
          this.authService.logout();
          this.messageservice.warningMessage('unauthorized acess');
          return next.handle(err);
        }
        if ([403].indexOf(err.status) !== -1) {
          this.authService.logout();
          this.messageservice.warningMessage('Access Denied');
          return next.handle(err);
        }
        return next.handle(req);
      })
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;

      const token = localStorage.getItem('token');

      if (token) {
        return this.authService.refreshToken().pipe(
          switchMap((token: any) => {
            this.isRefreshing = false;
            return next.handle(
              this.addTokenHeader(request, token.data.access_token)
            );
          }),
          catchError((err) => {
            this.isRefreshing = false;
            this.authService.logout();
            return throwError(err);
          })
        );
      } else {
        this.authService.logout();
        return next.handle(request);
      }
    }
  }
}
