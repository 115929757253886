import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor() { }
  // extended default timeout 5000 to 20000 ms and extendedTimeout 1000 to 3000 ms
  successMessage(msg:any) {
    Swal.fire({
      toast: true,
      position: 'top',
      showConfirmButton: false,
      icon: 'success',
      timer: 5000,
      title: msg
    });
  }

  warningMessage(msg:any) {
    Swal.fire({
      toast: true,
      position: 'top',
      showConfirmButton: false,
      icon: 'warning',
      timer: 5000,
      title: msg
    });
  }
}

