import { CommonHelper } from 'src/helper/common-helper';
import { LoaderService } from './../../../app/loader/loader.service';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { CommonApiService } from './../../../helper/common-api.service';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import * as JWT from 'jwt-decode';
import { AuthHelper } from 'src/helper/auth-helper';
import { MessageService } from 'src/helper/message.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isAuthenticated = false;
  Role: any;
  refreshToken: any;
  constructor(
    private http: HttpClient,
    private commonApiService: CommonApiService,
    private router: Router,
    private LoaderService: LoaderService,
    private commonData: CommonHelper,
    private messageService: MessageService
  ) { }

  login(params: any) {
    return new Promise((resolve, reject) => {
      this.LoaderService.show();
      this.commonApiService
        .getPromiseResponse({
          apiName: '/api/login',
          methodType: 'post',
          parameterObject: params,
        })
        .then((result) => {
          this.LoaderService.hide();
          if (result.id) {
            localStorage.setItem('token', result.token);
            localStorage.setItem('id', result.id);
            localStorage.setItem('userName', result.userName);
            localStorage.setItem('Rtoken', result.refreshToken);
            this.isAuthenticated = true;
            this.ValidateJWT();
          }
          resolve(result);
        })
        .catch((error: any) => {
          this, this.messageService.warningMessage('Invalid credentials');
          this.LoaderService.hide();
          reject(error);
        });
    });
  }

  ValidateJWT() {
    try {
      let token = JSON.parse(
        JSON.stringify(JWT.default(localStorage.getItem('token') || ''))
      );
      if (token && token.AccountData) {
        var userData = JSON.parse(token.AccountData);
        AuthHelper.UserDetails.Email = userData.Email;
        AuthHelper.UserDetails.FirstName = userData.FirstName;
        AuthHelper.UserDetails.LastName = userData.LastName;
        AuthHelper.UserDetails.AccountId = userData.AccountId;
        AuthHelper.UserDetails.IsAdmin = userData.IsAdmin;
        var data = localStorage.getItem('isseminar');
        if (data != 'true')
          localStorage.setItem('isseminar', userData.is_Seminar);
      }
      const date = new Date();
      const timeStamp = date.getTime();
      const timeout = token.exp * 1000 - timeStamp;
      if (timeout < 0) {
        this.logout();
      }
    } catch (e) {
      this.logout();
    }
  }

  HandleAuthRouteAccess() {
    this.ValidateJWT();
    this.router.navigate(['/session/dashboard']);
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/index'], {
      queryParams: {},
    });
    this.isAuthenticated = false;
  }

  getUserByToken(): Observable<any> {
    const auth = localStorage.getItem('token');
    if (!auth) {
      return of(undefined);
    }
    this.isAuthenticated = true;
    this.ValidateJWT();
    // this.getCommonData()
    return of();
  }

  // getCommonData()
  // {
  //   this.commonApiService.getPromiseResponse({
  //     apiName:'/api/data',methodType:'get',parameterObject: null
  //   }).then((result:any) => {
  //     var t = JSON.parse(result.data)
  //     this.commonData.updateCommonText(t);
  //   }).catch((err:any)=>{

  //   });

  // }

  ngOnDestroy() { }

  AuthRefreshToken(params: any) {
    return new Promise((resolve, reject) => {
      this.LoaderService.show();
      this.commonApiService
        .getPromiseResponse({
          apiName: '/auth/refresh_token',
          methodType: 'post',
          parameterObject: params,
        })
        .then((result) => {
          this.LoaderService.hide();
          if (result.id) {
            localStorage.setItem('token', result.token);
            localStorage.setItem('refreshtoken', result.refreshToken);
            localStorage.setItem('id', result.id);
            localStorage.setItem('userName', result.userName);
            localStorage.setItem('isAuthenticateduser', 'true');
            localStorage.setItem('isadmin', result.isAdmin);
            this.isAuthenticated = true;
            this.Role = result.lastLogin;
            this.refreshToken = result.refreshToken;
            this.ValidateJWT();
          }
          resolve(result);
        })
        .catch((error: any) => {
          this, this.messageService.warningMessage('Invalid credentials');
          this.LoaderService.hide();
          reject(error);
        });
    });
  }
}
