import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-translate-dialogs',
  templateUrl: './translate-dialogs.component.html',
  styleUrls: ['./translate-dialogs.component.css']
})
export class TranslateDialogsComponent implements OnInit {

  public title: any;
  public message: any;
  public data: any;
  constructor(private _activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    this.message = this.data.message;
    this.title = this.data.title;
  }

  confirm() {
    // we set dialog result as true on click on confirm button
    this.data = true;
    this._activeModal.close(true);
  }

  close() {
    this._activeModal.close(false);
  }
}
