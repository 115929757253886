// External Imports
import { Injectable, ViewContainerRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from './admin/shared/common-dialogs/confirmation-dialog/confirmation-dialog.component';
import { TranslateDialogsComponent } from './admin/shared/common-dialogs/translate-dialogs/translate-dialogs.component';

@Injectable()
export class DialogServices {
    constructor(private modalService: NgbModal) { }
    //hold defalut configuration of dialog
    private dialogConfiguration: any = { keyboard: false, backdrop: 'static', size: 'lg', scrollable: true, centered: true, modalDialogClass: "custom-modal" };

    /**
     * This method would configure the dialog and not configure set default configuration
     * @param config Configuration of dialog
     */
    initializeConfiguation(config: any) {
        if (config === undefined) {
            config = this.dialogConfiguration;
        } else {
            // Iterate and input unassigned configuration from default
            for (let key in this.dialogConfiguration) {
                if (config[key] === undefined || config[key] === null) {
                    config[key] = this.dialogConfiguration[key];
                }
            }
        }
        return config;
    }


    /**
     * This is used to open custom dialog
     * @param component compoment that need to be paased
     * @param dialogData for passing dynamic data
     * @param config dialog configuration
     */
    public addDialog(component: any, dialogData: any, config?: any) {
        try {
            config = this.initializeConfiguation(config);
            const modalRef: any = this.modalService.open(component, config);
            modalRef.componentInstance.data = dialogData;
            return modalRef;
        } catch (e) {
            // console.log(e);
        }
    }

    /**
* This is  used to open confirmation dialog
* @param dialogData for passing dynamic data
* @param config dialog configuration
*/
    public confirm(dialogData: { title: string, message: string, messageValue?: any, titleValue?: any, type?: string, isNeedtoTranslate?: boolean }, config?: any) {
        config = this.initializeConfiguation(config);
        const modalRef = this.modalService.open(ConfirmationDialogComponent, config);
        modalRef.componentInstance.data = dialogData;
        return modalRef;
    }

    public translate(dialogData: { title: string, message: string, messageValue?: any, titleValue?: any, type?: string, isNeedtoTranslate?: boolean }, config?: any) {
        config = this.initializeConfiguation(config);
        const modalRef = this.modalService.open(TranslateDialogsComponent, config);
        modalRef.componentInstance.data = dialogData;
        return modalRef;
    }
}
