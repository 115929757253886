<div class="modal-header">
    <h1 class="modal-title" id="modal-label">{{ title || "Confirmation" }}</h1>
  
    <button type="button" class="btn close-btn" data-bs-dismiss="modal" (click)="close()">
      <i class="fa-solid fa-xmark icon"></i>
    </button>
  </div>
  <div class="modal-body">
    <p [innerHTML]="message"></p>
  </div>
  <div class="modal-footer">
    <button type="button" id="btn_yes" class="btn btn-danger" (click)="confirm()">
      Ok
    </button>
  </div>