import { Observable, Subject } from 'rxjs';

export class CommonHelper {

    private CommonDetailsObservable = new Subject<any>();
    public static CommonDetails = {
        AnnouncementText : ``,
        AboutSection1 : ``,
        AboutSection2: ``,
        AboutOurPoser: ``,
        DiscipleshipTitle: ``,
        DiscipleshipDescription: ``,
        GiveUsingPhone: ``,
        GiveUsingPushPay: ``,
        YoutubeId: ``,
        DiscipleshipTUMIDescription:``,
        IndexSection2Title:``,
        KingSoopersTitle:``,
        KingSoopersDescription:``,
        iTimVideoId:``,
        iTimVideoTitle: ``,
        iTimVideoDescription: ``,
        iTimVideoPageTitle: ``,
        iTimVideoPageDescription: ``,
        TUMIPageTitle:``,
        TUMIPageDescription:``,
        getInvolvedHeading:``,
        getInvolvedYoutubeId:``,
        getInvolvedContent:``,
        AboutUsYoutubeID:``
    };

    public retrieveMappedCommonObjectObject(): Observable<any> {
        return this.CommonDetailsObservable.asObservable();
    }

    public updateCommonText(t:any)
    {
        CommonHelper.CommonDetails.AnnouncementText = t?.AnnouncementText;
        CommonHelper.CommonDetails.AboutSection1 = t?.AboutSection1;
        CommonHelper.CommonDetails.AboutSection2 = t?.AboutSection2;
        CommonHelper.CommonDetails.AboutOurPoser = t?.AboutOurPoser;
        CommonHelper.CommonDetails.DiscipleshipDescription = t?.DiscipleshipDescription;
        CommonHelper.CommonDetails.DiscipleshipTitle = t?.DiscipleshipTitle;
        CommonHelper.CommonDetails.YoutubeId = t?.YoutubeId;
        CommonHelper.CommonDetails.GiveUsingPhone = t?.GiveUsingPhone;
        CommonHelper.CommonDetails.GiveUsingPushPay = t?.GiveUsingPushPay;
        CommonHelper.CommonDetails.DiscipleshipTUMIDescription = t?.DiscipleshipTUMIDescription;
        CommonHelper.CommonDetails.IndexSection2Title = t?.IndexSection2Title;
        CommonHelper.CommonDetails.KingSoopersTitle = t?.KingSoopersTitle;
        CommonHelper.CommonDetails.KingSoopersDescription = t?.KingSoopersDescription;
        CommonHelper.CommonDetails.iTimVideoId = t?.iTimVideoId;
        CommonHelper.CommonDetails.iTimVideoTitle = t?.iTimVideoTitle;
        CommonHelper.CommonDetails.iTimVideoDescription = t?.iTimVideoDescription;
        CommonHelper.CommonDetails.iTimVideoPageTitle = t?.iTimVideoPageTitle;
        CommonHelper.CommonDetails.iTimVideoPageDescription = t?.iTimVideoPageDescription;
        CommonHelper.CommonDetails.TUMIPageTitle = t?.TUMIPageTitle;
        CommonHelper.CommonDetails.TUMIPageDescription = t?.TUMIPageDescription;
        CommonHelper.CommonDetails.getInvolvedHeading = t?.getInvolvedHeading;
        CommonHelper.CommonDetails.getInvolvedYoutubeId = t?.getInvolvedYoutubeId;
        CommonHelper.CommonDetails.getInvolvedContent = t?.getInvolvedContent;
        CommonHelper.CommonDetails.AboutUsYoutubeID = t?.AboutUsYoutubeID;
        this.CommonDetailsObservable.next(CommonHelper.CommonDetails);
    }    
}