// External Imports
import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoaderService } from 'src/app/loader/loader.service';
import { environment } from 'src/environments/environment';


// Internal Imports

@Injectable({
  providedIn: 'root'
})
export class CommonApiService {
  // Default Parameters
  httpDefaults: HTTPParam = {
    apiName: '',
    parameterObject: {},
    methodType: 'post',
    showLoading: true
  }

  // Loading Bar Watcher
  private isDataLoadingChange: EventEmitter<boolean> = new EventEmitter();
  // Tracking API Calls
  apiInProcess: any = []

  private hideLoader(parameters: HTTPParam) {
    if (parameters.showLoading) {
      let isExists = this.apiInProcess.findIndex((apiName: string) => apiName === parameters.apiName);
      if (isExists !== -1) {
        this.apiInProcess.splice(isExists, 1);
      }
      if (this.apiInProcess.length === 0) {
        this.loaderService.hide();
      }
    }
  }

  // Constructor
  constructor(private http: HttpClient, private loaderService: LoaderService) { }

  /**
   * POST REQUEST TO THE HTTP USING Observable
   * @param {string} apiName - API Name 
   * @param {*} [parameterObject] - Parameter to pass in request (By Default = {})
   * @returns {Observable<any>} 
   * @memberof CommonAPIService
   */
  private post(apiName: string, parameterObject?: any, header?: any): Observable<any> {
    return this.http.post(`${environment.apiUrl + apiName}`, parameterObject, { headers: header }).pipe(map((res: any) => {
      return res || {};
    }));
  }


  /**
   * PUT REQUEST TO THE HTTP USING Observable
   * @param {string} apiName - API Name 
   * @param {*} [parameterObject] - Parameter to pass in request (By Default = {})
   * @returns {Observable<any>} 
   * @memberof CommonAPIService
   */
  private put(apiName: string, parameterObject?: any, header?: any): Observable<any> {
    return this.http.put(`${environment.apiUrl + apiName}`, parameterObject, { headers: header }).pipe(map((res: any) => {
      return res || {};
    }));
  }

  /**
   * PATCH REQUEST TO THE HTTP USING Observable
   * @param {string} apiName - API Name 
   * @param {*} [parameterObject] - Parameter to pass in request (By Default = {})
   * @returns {Observable<any>} 
   * @memberof CommonAPIService
   */
  private patch(apiName: string, parameterObject?: any, header?: any): Observable<any> {
    return this.http.patch(`${environment.apiUrl + apiName}`, parameterObject, { headers: header }).pipe(map((res: any) => {
      return res || {};
    }));
  }


  /**
   * DELETE REQUEST TO THE HTTP USING Observable
   * @param {string} apiName - API Name 
   * @param {*} [parameterObject] - Parameter to pass in request (By Default = {})
   * @returns {Observable<any>} 
   * @memberof CommonAPIService
   */
  private delete(apiName: string, parameterObject?: any, header?: any): Observable<any> {
    return this.http.delete(`${environment.apiUrl + apiName}`, { params: parameterObject, headers: header }).pipe(map((res: any) => {
      return res || {};
    }));
  }

  /**
   * GET REQUEST TO THE HTTP USING Observable
   * @param {string} apiName - API Name 
   * @param {*} [parameterObject] - Parameter to pass in request (By Default = {})
   * @returns {Observable<any>} 
   * @memberof CommonAPIService
   */
  private get(apiName: string, parameterObject?: any,header?: any): Observable<any> {
    return this.http.get(`${environment.apiUrl + apiName}`, { params: parameterObject, headers: header }).pipe(map((res: any) => {
      return res || {};
    }));
  }

  /**
   * 
   * GET HTTP RESPONSE USING Observable
   * @param {string} apiName API NAME
   * @param {any} [parameterObject={}] 
   * @param {string} [methodType="post"] 
   * @param {boolean} [showLoading=true] 
   * @returns {*} 
   * @memberof CommonAPIService
   */
  public getObservableResponse(httpParam: HTTPParam): Observable<any> {
    const parameters = Object.assign({}, this.httpDefaults, httpParam);
    switch (parameters.methodType) {
      case 'post': return this.post(parameters.apiName, parameters.parameterObject);
      case 'get': return this.get(parameters.apiName, parameters.parameterObject);
      default : return of('');
    }
  }

  /**
  * 
  * GET HTTP RESPONSE USING PROMISE
  * @param {string} apiName API NAME
  * @param {any} [parameterObject={}] 
  * @param {string} [methodType="post"] 
  * @param {boolean} [showLoading=true] 
  * @returns {*} 
  * @memberof CommonAPIService
  */
  public getPromiseResponse(httpParam: HTTPParam, header?: any): Promise<any> {

    const parameters = Object.assign({}, this.httpDefaults, httpParam);
    if (parameters.showLoading) {
      this.apiInProcess.push(parameters.apiName);
      this.loaderService.show();
    }
    switch (parameters.methodType) {
      case 'post': return new Promise((resolve, reject) => {
        this.post(parameters.apiName, parameters.parameterObject, header).toPromise().then(response => {
          resolve(response);
          this.hideLoader(parameters);
        }, (error: HttpErrorResponse) => {
          this.hideLoader(parameters);
          this.loaderService.hide();
          reject(error.error);
        }).catch(error => {
          this.hideLoader(parameters);
          this.loaderService.hide();
          reject(error.error);
        });
      });

      case 'put': return new Promise((resolve, reject) => {
        this.put(parameters.apiName, parameters.parameterObject, header).toPromise().then(response => {
          resolve(response);
          this.hideLoader(parameters);
        }, (error: HttpErrorResponse) => {
          this.hideLoader(parameters);
          this.loaderService.hide();
          reject(error.error);
        }).catch(error => {
          this.hideLoader(parameters);
          this.loaderService.hide();
          reject(error.error);
        });
      });

      case 'patch': return new Promise((resolve, reject) => {
        this.patch(parameters.apiName, parameters.parameterObject, header).toPromise().then(response => {
          resolve(response);
          this.hideLoader(parameters);
        }, (error: HttpErrorResponse) => {
          this.hideLoader(parameters);
          this.loaderService.hide();
          reject(error.error);
        }).catch(error => {
          this.hideLoader(parameters);
          this.loaderService.hide();
          reject(error.error);
        });
      });

      case 'delete': return new Promise((resolve, reject) => {
        this.delete(parameters.apiName, parameters.parameterObject, header).toPromise().then(response => {
          resolve(response);
          this.hideLoader(parameters);
        }, (error: HttpErrorResponse) => {
          this.hideLoader(parameters);
          this.loaderService.hide();
          reject(error.error);
        }).catch(error => {
          this.hideLoader(parameters);
          this.loaderService.hide();
          reject(error.error);
        });
      });

      case 'get': return new Promise((resolve, reject) => {
        this.get(parameters.apiName, parameters.parameterObject,header).toPromise().then(response => {
          resolve(response);
          this.hideLoader(parameters);
        }, error => {
          this.hideLoader(parameters);
          this.loaderService.hide();
          reject(error.error);
        }).catch(error => {
          this.hideLoader(parameters);
          this.loaderService.hide();
          reject(error.error);
        });
      });

      default : return new Promise((resolve, reject) => {});

    }

    
  }
}


export interface HTTPParam {
  apiName: string,
  parameterObject?: any;
  methodType?: any;
  showLoading?: boolean
};